import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CodeBlock from './components/mdxComponents/codeBlock';
import { mapTabToClassName } from './utils/mapToClassName';
import { useSource } from './utils/SourceContext';

const TabContainer = styled.div({
  display: 'flex',
});

const InnerTabContainer = styled.div({
  marginTop: '5px',
  display: 'flex',
});

const InnerTab = styled.div(({ active, theme }) => ({
  cursor: 'pointer',
  padding: '20px',
  fontSize: '16px',
  lineHeight: '120%',
  borderBottom: `2px solid ${active ? 'blue' : 'transparent'}`,
  backgroundColor: active
    ? theme.colors.tabs.bgactive
    : theme.colors.tabs.bginactive,
  color: active
    ? theme.colors.tabs.coloractive
    : theme.colors.tabs.colorinactive,
  borderRadius: '8px',
}));

const CodeContainer = styled.div({
  position: 'relative',
  marginTop: '16px',
});

const CodeSample = styled('pre')({
  display: 'block',
  padding: '10px',
  borderRadius: '4px',
  backgroundColor: '#2d2d2d',
  color: '#ffffff',
  overflowX: 'auto',
});

const NotepadIcon = styled(FileCopyIcon)({
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
  color: 'white',
  '&:hover': {
    color: 'blue',
  },
});

const escapeHtml = (html) => {
  const div = document.createElement('div');
  div.innerText = html;
  return div.innerHTML;
};

const OneLevelTabs = ({ snippets }) => {
  const [activeInnerTab, setActiveInnerTab] = useState(0);
  const [modifiedCode, setModifiedCode] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const { sources } = useSource();

  const innerTabs = ['cURL', 'JavaScript', 'Java', 'Python'];

  useEffect(() => {
    const handleSnippets = async () => {
      let resolvedSnippets;

      if (snippets instanceof Promise) {
        resolvedSnippets = await snippets;
      } else {
        resolvedSnippets = snippets;
      }

      const snippet = resolvedSnippets.apis?.find(
        (s) =>
          s?.title?.toLowerCase().includes(innerTabs[activeInnerTab].toLowerCase())
      );
      const content = snippet ? snippet.content : '';
      setModifiedCode(modifyCodeContent(content));
    };

    handleSnippets();
  }, [activeInnerTab, accessToken, snippets, sources]);

  useEffect(() => {
    setAccessToken(
      document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('accessToken='))
        ?.split('=')[1]
    );
  }, []);

  const modifyCodeContent = (content) => {
    // Modify code content as needed
    const stringRegex = /SOME_STRING_VALUE/g;
    let modifiedContent = content.replace(stringRegex, 'Bearer ' + accessToken);
    return modifiedContent;
  };

  const handleInnerTabChange = (index) => {
    setActiveInnerTab(index);
  };

  return (
    <div>
      <InnerTabContainer>
        {innerTabs.map((tab, index) => (
          <InnerTab
            key={tab}
            active={activeInnerTab === index}
            onClick={() => handleInnerTabChange(index)}
          >
            {tab}
          </InnerTab>
        ))}
      </InnerTabContainer>

      <CodeContainer>
        <CodeBlock
          language={mapTabToClassName(innerTabs[activeInnerTab])}
          className={mapTabToClassName(innerTabs[activeInnerTab])}
        >
          {modifiedCode}
        </CodeBlock>
        <CopyToClipboard text={modifiedCode}>
          <NotepadIcon />
        </CopyToClipboard>
      </CodeContainer>
    </div>
  );
};

export default OneLevelTabs;
