import { metaData } from '../../../static/ApiData';
import React from 'react';

const FilterTable = ({ resource }) => {
  const data = metaData?.rest?.[0]?.resource?.find((option) => option.type === resource);
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>FilterName</th>
            <th>Description</th>
            <th>Default Value</th>
            <th>Allowed Values</th>
          </tr>
        </thead>
        <tbody>
          {data?.searchParam?.map((param, index) => (
            <tr key={index}>
              <td>{param?.name}</td>
              <td>{param?.documentation}</td>
              <td>{param?.defaultValue}</td>
              <td>{param?.allowedValues}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilterTable;
