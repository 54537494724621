import React, { createContext, useContext, useState } from 'react';

const MetadataContext = createContext();

export const MetadataProvider = ({ children }) => {
  const [metadata, setMetadata] = useState(null);

  return (
    <MetadataContext.Provider value={{ metadata, setMetadata }}>
      {children}
    </MetadataContext.Provider>
  );
};

export const useMetadata = () => {
  const context = useContext(MetadataContext);

  if (!context) {
    throw new Error("failed to create metadata context");
  }

  return context;
};
