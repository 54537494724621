import React from 'react';
import ApiTabs from "../ApiTabs";
import getSnippets from "../utils/getSnippets";

const ApiTemplateExport = ({ useCases, title, description }) => (
  <>
    <div className="css-hqmf0d-StyledMainWrapper e1m7sxnn0">
      <h3 className="heading3" id="Description">
        Description
      </h3>
      <p className="paragraph">{description}</p>

      <h3 className="heading3" id="Use Cases">
        Use Cases
      </h3>

      <ul>
        {useCases.map((useCase, index) => {
          const colonIndex = useCase.indexOf(':');
          const action = useCase.substring(0, colonIndex).trim();
          const description = useCase.substring(colonIndex + 1).trim();

          return (
            <li key={index}>
              <strong>{action}</strong>: {description}
            </li>
          );
        })}
      </ul>

      <h3 className="heading3" id="Request Sample">
        Request Sample
      </h3>
      {/* Adjust this part based on how you want to handle snippets */}
      <ApiTabs snippets={getSnippets(`${title}`, 'POST')} />
    </div>
  </>
);

export default ApiTemplateExport;
