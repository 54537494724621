import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import CodeBlock from './components/mdxComponents/codeBlock';
import { mapTabToClassName } from './utils/mapToClassName';
import { useSource } from './utils/SourceContext';

const TabContainer = styled.div({
  display: 'flex',
});

const OuterTab = styled.div(({ active, theme }) => ({
  cursor: 'pointer',
  padding: '20px',
  fontSize: "16px",
  lineHeight: "120%",
  borderBottom: `2px solid ${active ? 'blue' : 'transparent'}`,
  backgroundColor: active ? theme.colors.tabs.bgactive : theme.colors.tabs.bginactive,  // Fix syntax here
  color: active ? theme.colors.tabs.coloractive : theme.colors.tabs.colorinactive,
  borderRadius: "8px"
}));

const InnerTabContainer = styled.div({
  marginTop: "5px",
  display: 'flex',
});

const InnerTab = styled.div(({ active ,theme}) => ({
  cursor: 'pointer',
  padding: '20px',
  fontSize: "16px",
  lineHeight: "120%",
  borderBottom: `2px solid ${active ? 'blue' : 'transparent'}`,
  backgroundColor: active ? theme.colors.tabs.bgactive : theme.colors.tabs.bginactive,  // Fix syntax here
  color: active ? theme.colors.tabs.coloractive : theme.colors.tabs.colorinactive,
  borderRadius: "8px"
}));

const CodeContainer = styled.div({
  position: 'relative',
  marginTop: '16px',
});

const CodeSample = styled('pre')({
  display: 'block',
  padding: '10px',
  borderRadius: '4px',
  backgroundColor: '#2d2d2d',
  color: '#ffffff',
  overflowX: 'auto',
});

const NotepadIcon = styled(FileCopyIcon)({
  position: 'absolute',
  top: 0,
  right: 0,
  cursor: 'pointer',
  color: 'white',
  '&:hover': {
    color: 'blue',
  },
});

const escapeHtml = (html) => {
  const div = document.createElement('div');
  div.innerText = html;
  return div.innerHTML;
};


const ApiTabs = ({ snippets }) => {
  const [activeOuterTab, setActiveOuterTab] = useState(0);
  const [activeInnerTab, setActiveInnerTab] = useState(0);
  const [modifiedCode, setModifiedCode] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const {sources} = useSource();

  const outerTabs = ['Athena', 'Elation', 'Canvas'];
  const innerTabs = ['cURL', 'JavaScript', 'Java', 'Python'];


  useEffect(() => {
    const handleSnippets = async () => {
      let resolvedSnippets;

      if (snippets instanceof Promise) {
        // If snippets is a Promise, wait for it to resolve
        resolvedSnippets = await snippets;
      } else {
        // If snippets is not a Promise, use it directly
        resolvedSnippets = snippets;
      }

      const outerTab = outerTabs[activeOuterTab];
      const snippet = resolvedSnippets[outerTab]?.find((s) =>
        s?.title?.toLowerCase().includes(innerTabs[activeInnerTab].toLowerCase())
      );
      const content = snippet ? snippet.content : '';
      setModifiedCode(modifyCodeContent(content));
    };

    handleSnippets();
  }, [activeOuterTab, activeInnerTab, accessToken, snippets, sources, outerTabs]);


  useEffect(() => {
    setAccessToken(
      document.cookie
        .split(';')
        .find((cookie) => cookie.trim().startsWith('accessToken='))
        ?.split('=')[1]
    );
  }, []);


  const modifyCodeContent = (content) => {
    // Define regular expression to match SOME_STRING_VALUE
    const stringRegex = /SOME_STRING_VALUE/g;
    const sourceIdPlaceholder = '{{x-source-id}}';
  
    // Replace SOME_STRING_VALUE with the actual token
    let modifiedContent = content.replace(stringRegex, 'Bearer ' + accessToken);
    modifiedContent = modifiedContent.replace('{{xchange-url}}', 'https://xchange.xcaliberapis.com/api/v1');
  
    // Check if sources is not null or undefined
    if (sources) {
      // Replace {{x-source-id}} with the first element of the corresponding sources array
      const outerTabSources = sources[outerTabs[activeOuterTab]?.toUpperCase()] || [];
      const firstSourceId = outerTabSources.length > 0 ? outerTabSources[0]?.id : null;
      modifiedContent = modifiedContent.replace(sourceIdPlaceholder, firstSourceId || '');
    }
  
    // Perform additional manipulations if needed
    return modifiedContent;
  };

  const handleOuterTabChange = (index) => {
    setActiveOuterTab(index);
    setActiveInnerTab(0); // Reset inner tab when changing outer tab
  };

  const handleInnerTabChange = (index) => {
    setActiveInnerTab(index);
  };

  return (
    <div>
      <TabContainer>
        {outerTabs.map((tab, index) => (
          <OuterTab
            key={tab}
            active={activeOuterTab === index}
            onClick={() => handleOuterTabChange(index)}
          >
            {tab}
          </OuterTab>
        ))}
      </TabContainer>

      <InnerTabContainer>
        {innerTabs.map((tab, index) => (
          <InnerTab
            key={tab}
            active={activeInnerTab === index}
            onClick={() => handleInnerTabChange(index)}
          >
            {tab}
          </InnerTab>
        ))}
      </InnerTabContainer>

      <CodeContainer>
        <CodeBlock
          language={mapTabToClassName(innerTabs[activeInnerTab])}
          className={mapTabToClassName(innerTabs[activeInnerTab])}
        >
          {modifiedCode}
        </CodeBlock>
        <CopyToClipboard text={modifiedCode}>
          <NotepadIcon />
        </CopyToClipboard>
      </CodeContainer>
    </div>
  );
};

export default ApiTabs;
