export const mapTabToClassName = (tab) => {
  switch (tab.toLowerCase()) {
    case 'curl':
      return 'lang-bash';
    case 'java':
      return 'lang-javascript';
    case 'javascript':
      return 'lang-javascript';
    case 'python':
      return 'lang-python';
    default:
      return ''; // Add default class name if needed
  }
};
