// ApiTemplateDELETE.js
import React from 'react';
import ApiTabs from '../ApiTabs';
import getSnippets from '../utils/getSnippets';

const ApiTemplateDelete = ({ apis, useCases, title, description }) => (
  <>
    <div className="css-hqmf0d-StyledMainWrapper e1m7sxnn0">
      <h3 className="heading3" id="Description">
        Description
      </h3>
      <p className="paragraph">{description}</p>

      <h3 className="heading3" id="Request Sample">
        Request Sample
      </h3>
      {/* Adjust this part based on how you want to handle snippets */}
      <ApiTabs snippets={getSnippets(`${title}`, 'DELETE')} />

      <h3 className="heading3" id="Request Sample">
        Source Specific Details
      </h3>
      {apis?.length > 0 && (
        <div style={{ overflow: 'auto' }}>
          <table className="css-o4omta-StyledTable ev1gfv90">
            <thead>
              <tr>
                <th>EHR</th>
                <th>APIs</th>
              </tr>
            </thead>
            <tbody>
              {apis.map((api) => (
                <tr key={api.name}>
                  <td>{api.name}</td>
                  <td>
                    <ul>
                      {api.endpoint.map((endpoint, index) => (
                        <li key={index}>
                          <code>{endpoint}</code>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <h3 className="heading3" id="Use Cases">
        Use Cases
      </h3>
      <ul>
        {useCases.map((useCase, index) => {
          const colonIndex = useCase.indexOf(':');
          const action = useCase.substring(0, colonIndex).trim();
          const description = useCase.substring(colonIndex + 1).trim();

          return (
            <li key={index}>
              <strong>{action}</strong>: {description}
            </li>
          );
        })}
      </ul>
    </div>
  </>
);

export default ApiTemplateDelete;
