export const CoverageObject = {
    "resourceType": "Coverage",
    "id": "9876B1",
    "text": {
      "status": "generated",
      "div": "\u003cdiv xmlns\u003d\"http://www.w3.org/1999/xhtml\"\u003eA human-readable rendering of the coverage\u003c/div\u003e"
    },
    "identifier": [
      {
        "system": "http://benefitsinc.com/certificate",
        "value": "12345"
      }
    ],
    "status": "active",
    "type": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v3-ActCode",
          "code": "EHCPOL",
          "display": "extended healthcare"
        }
      ]
    },
    "policyHolder": {
      "reference": "http://benefitsinc.com/FHIR/Organization/CBI35"
    },
    "subscriber": {
      "reference": "Patient/4"
    },
    "beneficiary": {
      "reference": "Patient/4"
    },
    "dependent": "0",
    "relationship": {
      "coding": [
        {
          "code": "self"
        }
      ]
    },
    "period": {
      "start": "2011-05-23",
      "end": "2012-05-23"
    },
    "payor": [
      {
        "reference": "Organization/2"
      }
    ],
    "class": [
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "group"
            }
          ]
        },
        "value": "CB135",
        "name": "Corporate Baker\u0027s Inc. Local #35"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "subgroup"
            }
          ]
        },
        "value": "123",
        "name": "Trainee Part-time Benefits"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "plan"
            }
          ]
        },
        "value": "B37FC",
        "name": "Full Coverage: Medical, Dental, Pharmacy, Vision, EHC"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "subplan"
            }
          ]
        },
        "value": "P7",
        "name": "Includes afterlife benefits"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "class"
            }
          ]
        },
        "value": "SILVER",
        "name": "Silver: Family Plan spouse only"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "subclass"
            }
          ]
        },
        "value": "Tier2",
        "name": "Low deductable, max $20 copay"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "sequence"
            }
          ]
        },
        "value": "9"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "rxid"
            }
          ]
        },
        "value": "MDF12345"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "rxbin"
            }
          ]
        },
        "value": "987654"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "rxgroup"
            }
          ]
        },
        "value": "M35PT"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "rxpcn"
            }
          ]
        },
        "value": "234516"
      },
      {
        "type": {
          "coding": [
            {
              "system": "http://terminology.hl7.org/CodeSystem/coverage-class",
              "code": "sequence"
            }
          ]
        },
        "value": "9"
      }
    ],
    "meta": {
      "tag": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v3-ActReason",
          "code": "HTEST",
          "display": "test health data"
        }
      ]
    }
  }