import * as React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Button from '@mui/material/Button';
import Loadable from 'react-loadable';
import config from '../../config.js';
import LoadingProvider from './mdxComponents/loading';
import { DarkModeSwitch } from './DarkModeSwitch';
const help = require('./images/help.svg');

const isSearchEnabled = config.header.search && config.header.search.enabled ? true : false;

let searchIndices = [];

if (isSearchEnabled && config.header.search.indexName) {
  searchIndices.push({
    name: `${config.header.search.indexName}`,
    title: `Results`,
    hitComp: `PageHit`,
  });
}

import Sidebar from './sidebar';
import Link from './link.js';
import { useAuth0 } from '@auth0/auth0-react';

const LoadableComponent = Loadable({
  loader: () => import('./search/index'),
  loading: LoadingProvider,
});

function myFunction() {
  var x = document.getElementById('navbar');

  if (x.className === 'topnav') {
    x.className += ' responsive';
  } else {
    x.className = 'topnav';
  }
}

const StyledBgDiv = styled('div')`
  height: 60px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #f8f8f8;
  position: relative;
  display: none;
  background: ${(props) => (props.isDarkThemeActive ? '#001932' : undefined)};

  @media (max-width: 767px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  && {
    color: ${(props) => (isDarkThemeActive ? '#001932' : 'rgb(255,255,255)')};
    font-size: 16px;
  }
`;

const StyledLogoLink = styled(Link)`
  color: #000;
  width: 150px;
  font-size: 32px;
  font-weight: 500;
  line-heigt: 120%;
`;

const StyledHeader = styled('div')`
  color: #000;
  font-size: 32px;
  font-weight: 500;
  line-height: 120%;
`;

const Header = ({ location, isDarkThemeActive, toggleActiveTheme }) => (
  <StaticQuery
    query={graphql`
      query headerTitleQuery {
        site {
          siteMetadata {
            headerTitle
            githubUrl
            helpUrl
            tweetText
            logo {
              link
              image
            }
            headerLinks {
              link
              text
            }
          }
        }
      }
    `}
    render={(data) => {
      const {logout} = useAuth0();

      const handleLogout = () => {
        logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        });
      };

      const twitter = require('./images/twitter.svg');

      const discordBrandsBlock = require('./images/discord-brands-block.svg');

      const twitterBrandsBlock = require('./images/twitter-brands-block.svg');

      const {
        site: {
          siteMetadata: { headerTitle, githubUrl, helpUrl, tweetText, logo, headerLinks },
        },
      } = data;

      return (
        <nav
          className={'navBarDefault'}
          style={{
            backgroundColor: isDarkThemeActive ? '#001932' : '#f7fafc',
            justifyContent: 'space-between',
            color: '#f7fafc',
          }}
        >
          <div>
            <StyledLogoLink to="/">
              <StyledHeader
                style={{
                  color: isDarkThemeActive ? '#fff' : '#000',
                  fontSize: '32px',
                  fontWeight: '500',
                  lineHeight: '120%',
                }}
              >
                xcaliber
              </StyledHeader>
            </StyledLogoLink>
          </div>
          <div id="navbar" className={'topnav'}>
            <div className={'visibleMobile'}>
              <Sidebar location={location} />
              <hr />
            </div>
            <ul className={'navBarUL navBarNav navBarULRight'}>
              {headerLinks.map((link, key) => {
                if (link.link !== '' && link.text !== '') {
                  return (
                    <li key={key}>
                      <a
                        className="sidebarLink"
                        href={link.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        dangerouslySetInnerHTML={{ __html: link.text }}
                      />
                    </li>
                  );
                }
              })}
              {helpUrl !== '' ? (
                <li>
                  <a href={helpUrl}>
                    <img src={help} alt={'Help icon'} />
                  </a>
                </li>
              ) : null}

              <li>
                <div
                  className={'headerButtons'}
                  onClick={handleLogout}
                >
                  <div
                    variant="text"
                    style={{
                      color: '#414552',
                      fontSize: '14px',
                      backgroundColor: '#fff',
                      boxShadow: '0 0 5px rgba(0, 0, 0, .2)',
                      fontFamily: 'Montserrat, sans-serif',
                      borderRadius: '4px',
                      paddingTop: '8px',
                      paddingBottom: '8px',
                      padding: '9px 15px',
                    }}
                  >
                    Sign Out
                  </div>
                </div>
              </li>

              <li>
                <DarkModeSwitch
                  isDarkThemeActive={isDarkThemeActive}
                  toggleActiveTheme={toggleActiveTheme}
                />
              </li>
            </ul>
          </div>
        </nav>
      );
    }}
  />
);

export default Header;
