export const MedicationObject = {
    "resourceType": "Medication",
    "id": "medicationexample1",
    "text": {
      "status": "generated",
      "div": "\u003cdiv xmlns\u003d\"http://www.w3.org/1999/xhtml\"\u003eAmoxicillin 250mg/5ml Suspension\u003c/div\u003e"
    },
    "code": {
      "text": "Amoxicillin 250mg/5ml Suspension"
    },
    "meta": {
      "tag": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v3-ActReason",
          "code": "HTEST",
          "display": "test health data"
        }
      ]
    }
  }