import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSource } from '../SourceContext';
import Cookies from 'js-cookie';

const useAuthRedirect = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const { setSources } = useSource();
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        if (!isLoading && !isAuthenticated && !user) {
          // Redirect only if the user is not authenticated
          await loginWithRedirect();
        } else if (isAuthenticated) {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: 'https://docs.xcaliber.health',
              redirect_uri: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
            },
          });

          const idTokenClaims = await getIdTokenClaims();
          const tokenEndpoint = idTokenClaims?.instanceUrl + '/api/v1/auth/tokenV2';

          const tokenRequestData = {
            user: {
              userId: user?.sub,
            },
            grantType: 'client_credentials',
          };

          const tokenRequestConfig = {
            method: 'POST',
            headers: {
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              Authorization: `${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(tokenRequestData),
          };

          const tokenResponse = await fetch(tokenEndpoint, tokenRequestConfig);
          const tokenData = await tokenResponse.json();

          // Save token into cookies with 30-minute expiration
          Cookies.set('accessToken', tokenData?.token, { expires: 0.0208 }); // 30 minutes in days

          const decodeEndpoint = idTokenClaims?.instanceUrl + '/api/v1/auth/decode';

          const decodeResponse = await fetch(decodeEndpoint, {
            headers: {
              Authorization: `Bearer ${tokenData?.token}`,
            },
          });

          const decodedTokenData = await decodeResponse.json();

          const accountId = decodedTokenData['xcaliber-health.account.id'];
          const instanceId = decodedTokenData['xcaliber-health.instance.id'];

          const sourcesEndpoint = `${idTokenClaims?.instanceUrl}/api/v1/accounts/${accountId}/instances/${instanceId}/sources`;

          const sourcesResponse = await fetch(sourcesEndpoint, {
            headers: {
              Authorization: `Bearer ${tokenData?.token}`,
            },
          });

          const sourceIds = await sourcesResponse.json();

          const groupedSources = groupSourcesByType(sourceIds);
          setSources(groupedSources);

        }
      } catch (error) {
        console.error('Error in useAuthRedirect:', error);
      }
    };

    const fetchMetaData = async (token) => {
      const idTokenClaims = await getIdTokenClaims();
      const metadataEndpoint = idTokenClaims?.xchangeUrl + '/api/v1/meta';

      const metadataResponse = await fetch(metadataEndpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return await metadataResponse.json();
    };

    const groupSourcesByType = (sources) => {
      const groupedSources = {};

      sources.forEach((source) => {
        const type = source.type;

        if (!groupedSources[type]) {
          groupedSources[type] = [];
        }
        if (source?.status === 'ACTIVE') groupedSources[type].push(source);
      });

      return groupedSources;
    };

    handleRedirect();
  }, [isLoading, isAuthenticated, loginWithRedirect, user, getAccessTokenSilently]);

  return { isLoading, isAuthenticated };
};

export default useAuthRedirect;
