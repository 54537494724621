import {athenaApis,elationApis} from "../../static/ApiData";
export default async (name, method) => {
  const filteredAthenaApis = athenaApis.filter(
    (api) => api.name === name && (api.method === method || method?.includes(api.method))
  )?.[0]?.snippets;
  const filteredElationApis = elationApis.filter(
    (api) => api.name === name && (api.method === method || method?.includes(api.method))
  )?.[0]?.snippets;
  const filteredCanvasApis = elationApis.filter(
    (api) => api.name === name && (api.method === method || method?.includes(api.method))
  )?.[0]?.snippets;
  return {
    Athena: filteredAthenaApis,
    Elation: filteredElationApis,
    Canvas: filteredCanvasApis,
  };
};
