export const SlotObject = {
    "resourceType": "Slot",
    "id": "example",
    "text": {
      "status": "generated",
      "div": "\u003cdiv xmlns\u003d\"http://www.w3.org/1999/xhtml\"\u003e\n\t\t\t25 Dec 2013 9:15am - 9:30am: \u003cb\u003eBusy\u003c/b\u003e Physiotherapy\n\t\t\u003c/div\u003e"
    },
    "serviceCategory": [
      {
        "coding": [
          {
            "code": "17",
            "display": "General Practice"
          }
        ]
      }
    ],
    "serviceType": [
      {
        "coding": [
          {
            "code": "57",
            "display": "Immunization"
          }
        ]
      }
    ],
    "specialty": [
      {
        "coding": [
          {
            "code": "408480009",
            "display": "Clinical immunology"
          }
        ]
      }
    ],
    "appointmentType": {
      "coding": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v2-0276",
          "code": "WALKIN",
          "display": "A previously unscheduled walk-in visit"
        }
      ]
    },
    "schedule": {
      "reference": "Schedule/example"
    },
    "status": "free",
    "start": "2013-12-25T09:15:00Z",
    "end": "2013-12-25T09:30:00Z",
    "comment": "Assessments should be performed before requesting appointments in this slot.",
    "meta": {
      "tag": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v3-ActReason",
          "code": "HTEST",
          "display": "test health data"
        }
      ]
    }
  }