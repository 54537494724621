// ApiTemplate.js
import ApiTabs from '../ApiTabs';
import getSnippets from '../utils/getSnippets';
import React from 'react';

const ApiTemplateGetById = ({ apis, useCases, title, description, resource }) => (
  <>
    <div className="css-hqmf0d-StyledMainWrapper e1m7sxnn0">
      <h3 className="heading3" id="Description">
        Description
      </h3>
      <p className="paragraph">{description}</p>

      <h3 className="heading3" id="Use Cases">
        Use Cases
      </h3>
      <ul>
        {useCases.map((useCase, index) => {
          const colonIndex = useCase.indexOf(':');
          const action = useCase.substring(0, colonIndex).trim();
          const description = useCase.substring(colonIndex + 1).trim();

          return (
            <li key={index}>
              <strong>{action}</strong>: {description}
            </li>
          );
        })}
      </ul>

      <h3 className="heading3" id="Request Sample">
        Request Sample
      </h3>
      <ApiTabs snippets={getSnippets(`${title}`, 'GET')} />

      <h3 className="heading3" id="Source Specific Details">
        Source Specific Details
      </h3>
      {apis?.length > 0 && (
        <div style={{ overflow: 'auto' }}>
          <table className="css-o4omta-StyledTable ev1gfv90">
            <thead>
              <tr>
                <th>EHR</th>
                <th>APIs</th>
                <th>mandatory filters</th>
                <th>optional filters</th>
              </tr>
            </thead>
            <tbody>
              {apis.map((api) => (
                <tr key={api.name}>
                  <td>{api.name}</td>
                  <td>
                    <ul>
                      {api.endpoint.map((endpoint, index) => (
                        <li key={index}>
                          <code>{endpoint}</code>
                        </li>
                      ))}
                    </ul>
                  </td>
                  <td>
                    {api.mandatoryFilters ? (
                      <ul>
                        {api.mandatoryFilters.map((filter) => (
                          <li key={filter}>{filter}</li>
                        ))}
                      </ul>
                    ) : null}
                  </td>
                  <td>
                    {api.optionalFilters ? (
                      <ul>
                        {api.optionalFilters.map((filter) => (
                          <li key={filter}>{filter}</li>
                        ))}
                      </ul>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  </>
);

export default ApiTemplateGetById;
