import React from 'react';
import dataFields from '../data/dataFields';
import { metaData } from '../../static/ApiData';

const ObjectDisplay = ({ resourceObj, type }) => {
  const data = metaData?.rest?.[0]?.resource?.find((option) => option.type === type);
  const dataFieldResource = dataFields[type];
  const renderDataFields = (dataFields) => {
    return (
      <table className="css-o4omta-StyledTable ev1gfv90">
        <thead>
          <tr>
            <th>Data Field</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {dataFields.map((field, index) => {
  
            const dataField = field['Data Fields'].toString();
            const fieldType = field['Type'].toString();
            const fieldDescription = field['Description'].toString();
  
            return (
              <tr key={index}>
                <td>{dataField}</td>
                <td>{fieldType}</td>
                <td>{fieldDescription}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };
  

  const renderExtensions = () => {
    const flattenObject = (obj) => {
      return Object.entries(obj).flatMap(([key, value]) => {
        const currentKey = key;
  
        if (typeof value === 'object' && value !== null) {
          // Recursively flatten nested objects
          return flattenObject(value);
        } else {
          // Return the flattened key-value pair
          return [{ key: currentKey, value }];
        }
      });
    };
  
    return (
      <table className="css-o4omta-StyledTable ev1gfv90">
        <thead>
          <tr>
            <th>Name</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {data?.extensions &&
            flattenObject(data?.extensions).map(({ key, value }, index) => (
              <tr key={index}>
                <td>{key}</td>
                <td>{value.toString()}</td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };
  

  const jsonString = JSON.stringify(resourceObj, null, 2);

  return (
    <div>
      <h2 className="heading2" id="Object Definition">
        Object Definition
      </h2>
      <pre style={{ overflow: 'auto' }}>{jsonString}</pre>

      <h2 className="heading2" id="Data Fields">
        Data Fields
      </h2>
      {dataFieldResource && renderDataFields(dataFieldResource)}

      <h2 className="heading2" id="Extensions">
        Extensions
      </h2>
      {metaData && renderExtensions()}
    </div>
  );
};

export default ObjectDisplay;
