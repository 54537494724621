export const CoverageEligibilityRequestObject = {
    "resourceType": "CoverageEligibilityRequest",
    "id": "52345",
    "text": {
      "status": "generated",
      "div": "\u003cdiv xmlns\u003d\"http://www.w3.org/1999/xhtml\"\u003eA human-readable rendering of the CoverageEligibilityRequest\u003c/div\u003e"
    },
    "identifier": [
      {
        "system": "http://happyvalley.com/coverageelegibilityrequest",
        "value": "52345"
      }
    ],
    "status": "active",
    "priority": {
      "coding": [
        {
          "code": "normal"
        }
      ]
    },
    "purpose": [
      "validation"
    ],
    "patient": {
      "reference": "Patient/pat1"
    },
    "created": "2014-08-16",
    "provider": {
      "reference": "Organization/1"
    },
    "insurer": {
      "reference": "Organization/2"
    },
    "insurance": [
      {
        "focal": true,
        "coverage": {
          "reference": "Coverage/9876B1"
        }
      }
    ],
    "meta": {
      "tag": [
        {
          "system": "http://terminology.hl7.org/CodeSystem/v3-ActReason",
          "code": "HTEST",
          "display": "test health data"
        }
      ]
    }
  }