import * as React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import { useEffect } from 'react';
import ThemeProvider from './theme/themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RightSidebar from './rightSidebar';
import config from '../../config.js';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};

  .sideBarUL li a {
    color: ${({ theme }) => theme.colors.text};
  }

  .sideBarUL .item > a:hover {
    background-color: ${({ theme }) => theme.colors.hover};
    color: #fff !important;

    /* background: #F8F8F8 */
  }

  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 10%;
  padding-top: 3rem;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    background: ${({ theme }) => theme.colors.background};
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    padding-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  width: 298px;
`;

const RightSideBarWidth = styled('div')`
  width: 224px;
`;

const ChatIcon = styled(ChatBubbleOutlineIcon)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff8c00; // Orange color
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
  color: white;
  padding: 7px;
`;

const IframeContainer = styled('div')`
  position: fixed;
  bottom: 90px;
  right: 20px;
  width: 20%;
  height: 40%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 998;
`;

const Layout = ({ children, location }) => {
  useEffect(() => {
    const iframeContainer = document.getElementById('iframeContainer');
    const chatIcon = document.getElementById('chatIcon');
  
    const handleChatIconClick = () => {
      iframeContainer.style.display = 'block';
      chatIcon.style.display = 'none';
    };
  
    const handleIframeContainerClick = (event) => {
      // Check if the click is outside the iframe container and chat icon
      if (
        !event.target.closest('#iframeContainer') &&
        !event.target.closest('#chatIcon')
      ) {
        iframeContainer.style.display = 'none';
        chatIcon.style.display = 'block';
      }
    };
  
    const handleDocumentClick = (event) => {
      // Check if the click is outside the iframe container and chat icon
      if (
        !event.target.closest('#iframeContainer') &&
        !event.target.closest('#chatIcon')
      ) {
        iframeContainer.style.display = 'none';
        chatIcon.style.display = 'block';
      }
    };
  
    chatIcon.addEventListener('click', handleChatIconClick);
    iframeContainer.addEventListener('click', handleIframeContainerClick);
    document.addEventListener('click', handleDocumentClick);
  
    return () => {
      chatIcon.removeEventListener('click', handleChatIconClick);
      iframeContainer.removeEventListener('click', handleIframeContainerClick);
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  return (
    <ThemeProvider location={location}>
      <MDXProvider components={mdxComponents}>
        <Wrapper>
          <LeftSideBarWidth className={'hiddenMobile'}>
            <Sidebar location={location} />
          </LeftSideBarWidth>
          {config.sidebar.title ? (
            <div
              className={'sidebarTitle sideBarShow'}
              dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
            />
          ) : null}
          <Content>
            <MaxWidth>{children}</MaxWidth>
          </Content>
          <RightSideBarWidth className={'hiddenMobile'}>
            <RightSidebar location={location} />
          </RightSideBarWidth>

          {/* Chat Icon */}
          <ChatIcon id="chatIcon">
            {/* Add your chat icon component or SVG here */}
            🗨️
          </ChatIcon>

          {/* Iframe Container */}
          <IframeContainer id="iframeContainer" style={{ display: 'none' }}>
            <iframe
              src="https://embed.fixie.ai/agents/c51f7f9c-9a9f-4bb3-9be4-2e644a36c7cf?debug=1"
              allow="clipboard-write"
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </IframeContainer>
        </Wrapper>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default Layout;