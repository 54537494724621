const baseTheme = {
  fonts: {
    mono: '"SF Mono", "Roboto Mono", Menlo, monospace',
  },
};

const lightTheme = {
  ...baseTheme,
  colors: {
    background: '#fff',
    heading: '#000',
    text: '#3B454E',
    preFormattedText: 'rgb(245, 247, 249)',
    link: '#1000EE',
    hover: "#a2dfda",
    tabs: {
      bgactive: '#0c253f',
      bginactive: '#f3f7f9',
      coloractive: '#fff',
      colorinactive: 'black'
    }
  },
};

const darkTheme = {
  ...baseTheme,
  colors: {
    background: '#001933',
    heading: '#fff',
    text: '#fff',
    preFormattedText: '#000',
    link: '#1ED3C6',
    hover: "#526967",
    tabs: {
      bginactive: '#0c253f',
      bgactive: '#f3f7f9',
      colorinactive: '#fff',
      coloractive: 'black'
    }
  },
};

export { lightTheme, darkTheme };
