import * as React from 'react';
import styled from '@emotion/styled';

import CodeBlock from './codeBlock';
import AnchorTag from './anchor';

const StyledPre = styled('pre')`
  padding: 16px;
  background: ${(props) => props.theme.colors.preFormattedText};
`;

const StyledTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;

  th, td {
    padding: 12px;
    border: 1px solid #ddd;
  }

  th {
    background-color: #a2b0bd;
  }
`;

const generateId = (children) => {
  if (Array.isArray(children)) {
    return children
      .filter((child) => typeof child === 'string')
      .join(''); // Concatenate strings
  } else {
    return children;
  }
};



export default {
  h1: (props) => (
    <h1
      className="heading1"
      id={generateId(props.children)}
      {...props}
    />
  ),
  h2: (props) => (
    <h2
      className="heading2"
      id={generateId(props.children)}
      {...props}
    />
  ),
  h3: (props) => (
    <h3
      className="heading3"
      id={generateId(props.children)}
      {...props}
    />
  ),
  h4: (props) => (
    <h4
      className="heading4"
      id={generateId(props.children)}
      {...props}
    />
  ),
  h5: (props) => (
    <h5
      className="heading5"
      id={generateId(props.children)}
      {...props}
    />
  ),
  h6: (props) => (
    <h6
      className="heading6"
      id={generateId(props.children)}
      {...props}
    />
  ),
  p: (props) => <p className="paragraph" {...props} />,
  pre: (props) => (
    <StyledPre>
      <pre {...props} />
    </StyledPre>
  ),
  code: CodeBlock,
  a: AnchorTag,
  table: (props) => (
    <div style={{overflow: "auto"}}>
    <StyledTable {...props} />
    </div>
  ),
  // TODO add `img`
  // TODO add `blockquote`
  // TODO add `ul`
  // TODO add `li`
};
