import React, { createContext, useContext, useState } from 'react';

const SourceContext = createContext();

export const SourceProvider = ({ children }) => {
  const [sources, setSources] = useState(null);

  return (
    <SourceContext.Provider value={{ sources, setSources }}>
      {children}
    </SourceContext.Provider>
  );
};

export const useSource = () => {
  const context = useContext(SourceContext);

  if (!context) {
    throw new Error('useToken must be used within a SourceProvider');
  }

  return context;
};
